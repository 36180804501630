<template>
  <div class="w-full pt-5 pb-5">
    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <div class="flex">
        <back-button
          label="Back"
          @onClick="$router.back()"
          variant="secondary"
          class="mr-4"
        />
        <div
          class="mt-4"
          style="border-left: 1px solid #878e99; height: 1.5rem"
        />

        <div style="width: 230px">
          <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
            Disengage
          </h1>
        </div>

        <div class="w-7/12 mt-4">
          <Breadcrumb :items="breadcrumbs" />
        </div>
      </div>
      <div class="px-3">
        <card class="p-5 mt-6">
          <div class="border border-dashed p-3">
            <div class="w-full mb-4">
              <p class="text-lg font-semibold text-darkPurple">
                Disengagement Details
              </p>
            </div>
            <div class="flex flex-col gap-2">
              <div class="flex justify-evenly gap-2 w-full">
                <card class="p-3 w-full">
                  <p class="text-xs font-black uppercase text-romanSilver">
                    effective date:
                  </p>
                  <p class="font-semibold text-lg text-darkPurple">
                    <span v-if="getDisengagedDetails.confirm.effectiveDate">
                      {{ $DATEFORMAT(new Date(getDisengagedDetails.confirm.effectiveDate), "MMMM dd, yyyy") }}
                    </span>
                    <span v-else>-</span>
                  </p>
                </card>
                <card class="p-3 w-full">
                  <p class="text-xs font-black uppercase text-romanSilver">
                    reason for disengagement:
                  </p>
                  <p class="font-semibold text-lg text-darkPurple">
                    <span v-if="getDisengagedDetails.reason">
                      {{ getDisengagedDetails.reason }}
                    </span>
                    <span v-else>-</span>
                  </p>
                </card>
              </div>
              <card class="p-3 w-full">
                <p class="text-xs font-black uppercase text-romanSilver">
                  Comments
                </p>
                <p class="font-semibold text-lg text-darkPurple">
                    <span v-if="getDisengagedDetails.disengage">
                    {{ getDisengagedDetails.disengage.comments }}
                  </span>
                  <span v-else>-</span>
                </p>
              </card>
            </div>
          </div>
        </card>
        <card class="p-5 mt-4">
          <div class="my-4" style="border: 0.2px solid #878e99" />
          <div class="w-full flex">
            <div class="flex flex-col flex-grow">
              <p class="text-base font-semibold">Submitted By:</p>
              <div class="flex flex-grow py-2">
                <img
                  v-if="
                    approvalsData.photo != null && approvalsData.photo != ''
                  "
                  class="mr-2"
                  alt="photo"
                  style="width: 101px; height: 101px"
                  :src="approvalsData.photo"
                />
                <div
                  style="height: 101px; width: 101px; border-radius: 5px"
                  class="text-blueCrayola border text-center font-semibold text-4xl pt-7 mr-2"
                  v-else
                >
                  {{
                    $getInitials(
                      `${approvalsData.fname} ${approvalsData.lname}`
                    )
                  }}
                </div>
                <div class="flex flex-col">
                  <div class="flex flex-col">
                    <span class="text-lg text-optimaBlack font-bold mr-1">
                      {{ `${approvalsData.fname} ${approvalsData.lname}` }}
                    </span>
                    <span class="text-lg text-blueCrayola font-bold">
                      {{
                        `${
                          approvalsData.userDesignation
                            ? approvalsData.userDesignation.name
                            : ""
                        }`
                      }}
                    </span>
                  </div>
                </div>
                <div class="border h-20 mx-5 -mt-3" style="width: 1px" />
                <div class="flex">
                  <icon
                    icon-name="icon-message-outline"
                    size="xs"
                    class="-mt-11"
                  />
                  <span class="text-flame text-base font-semibold mt-6 ml-4">
                    Send Message
                  </span>
                </div>
              </div>
            </div>

            <div class="flex flex-col flex-grow">
              <p class="text-base font-semibold">Disengage:</p>
              <div class="flex">
                <div class="flex flex-grow py-3">
                  <img
                    v-if="
                      getDisengagedDetails.confirmationFor.photo != null &&
                      getDisengagedDetails.confirmationFor.photo != ''
                    "
                    class="mr-2"
                    alt="photo"
                    style="width: 101px; height: 101px"
                    :src="getDisengagedDetails.confirmationFor.photo"
                  />
                  <div
                    style="height: 101px; width: 101px; border-radius: 5px"
                    class="text-blueCrayola border text-center font-semibold text-4xl pt-7 mr-2"
                    v-else
                  >
                    {{
                      $getInitials(
                        `${ getDisengagedDetails.confirmationFor.fname } ${ getDisengagedDetails.confirmationFor.lname}`
                      )
                    }}
                  </div>
                  <div class="flex flex-col">
                    <p class="text-lg font-bold text-darkPurple">
                      {{
                        `${ getDisengagedDetails.confirmationFor.fname} ${ getDisengagedDetails.confirmationFor.lname}`
                      }}
                    </p>
                    <span class="flex my-1">
                      <p
                        class="font-semibold uppercase text-romanSilver mr-3 mt-1"
                        style="font-size: 10px"
                      >
                        Designation:
                      </p>
                      <p class="font-semibold uppercase text-sm text-jet">
                        {{
                           getDisengagedDetails.confirmationFor.userDesignation ?
                           getDisengagedDetails.confirmationFor.userDesignation.name :
                          '---'
                        }}
                      </p>
                    </span>
                    <span class="flex my-1">
                      <p
                        class="font-semibold uppercase text-romanSilver mr-3 mt-1"
                        style="font-size: 10px"
                      >
                        Function:
                      </p>
                      <p class="font-semibold uppercase text-sm text-jet">
                        {{
                           getDisengagedDetails.confirmationFor.orgFunction ?
                           getDisengagedDetails.confirmationFor.orgFunction.name :
                          '---'
                        }}
                      </p>
                    </span>
                    <span class="flex my-1">
                      <p
                        class="font-semibold uppercase text-romanSilver mr-3 mt-1"
                        style="font-size: 10px"
                      >
                        Office Location:
                      </p>
                      <p class="font-semibold uppercase text-sm text-jet">
                        {{
                           getDisengagedDetails.confirmationFor.employeeOffice ?
                           getDisengagedDetails.confirmationFor.employeeOffice.name :
                          '---'
                        }}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-col flex-grow" v-if="$route.query.slug !== 'history'">
              <p class="text-base font-semibold">Current Approver:</p>
              <div class="flex">
                <div class="flex flex-grow py-3">
                  <img
                    v-if="
                      approverDetails.photo != null &&
                      approverDetails.photo != ''
                    "
                    class="mr-2"
                    alt="photo"
                    style="width: 101px; height: 101px"
                    :src="approverDetails.photo"
                  />
                  <div
                    style="height: 101px; width: 101px; border-radius: 5px"
                    class="text-blueCrayola border text-center font-semibold text-4xl pt-7 mr-2"
                    v-else
                  >
                    {{
                      $getInitials(
                        `${approverDetails.fname} ${approverDetails.lname}`
                      )
                    }}
                  </div>
                  <div class="flex flex-col">
                    <p class="text-lg font-bold text-darkPurple">
                      {{ `${approverDetails.fname} ${approverDetails.lname}` }}
                    </p>
                    <span class="flex my-1">
                      <p
                        class="font-semibold uppercase text-romanSilver mr-3 mt-1"
                        style="font-size: 10px"
                      >
                        Designation:
                      </p>
                      <p class="font-semibold uppercase text-sm text-jet">
                        <span v-if="approverDetails.userDesignation">
                          {{ approverDetails.userDesignation.name }}
                        </span>
                      </p>
                    </span>
                    <span class="flex my-1">
                      <p
                        class="font-semibold uppercase text-romanSilver mr-3 mt-1"
                        style="font-size: 10px"
                      >
                        Function:
                      </p>
                      <p class="font-semibold uppercase text-sm text-jet">
                        <span v-if="approverDetails.orgFunction">
                          {{ approverDetails.orgFunction.name }}
                        </span>
                      </p>
                    </span>
                    <span class="flex my-1">
                      <p
                        class="font-semibold uppercase text-romanSilver mr-3 mt-1"
                        style="font-size: 10px"
                      >
                        Office Location:
                      </p>
                      <p class="font-semibold uppercase text-sm text-jet">
                        <span v-if="approverDetails.employeeOffice">
                          {{ approverDetails.employeeOffice.name }}
                        </span>
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
        <card class="p-5 mt-4">
          <div class="w-full flex">
            <div class="flex flex-col flex-grow py-2">
              <p class="text-lg font-bold">Approvers ({{ approversNumber }})</p>
              <p class="text-sm">
                You can quickly approve or dissaprove from here.
              </p>
              <p class="text-sm">
                You also see other approvers’ responses and comments within the
                approval trail.
              </p>
            </div>
            <div class="flex flex-grow flex-col">
              <span class="flex">
                <p class="font-bold text-darkPurple text-xl">
                  {{ Math.ceil(approvalsData.requests.approvalPercentage) }}%
                </p>
                <p class="text-romanSilver ml-1 mt-1 text-sm">Completed</p>
              </span>
              <ProgressBar
                :value="approvalsData.requests.approvalPercentage"
                class="mt-2"
              />
              <p class="font-semibold text-xs mt-2 text-romanSilver uppercase">
                {{ approvalsData.requests.approversLeft }} approvers left
              </p>
            </div>
            <div class="flex flex-grow justify-end">
              <Button
                class="text-dynamicBackBtn border border-solid border-dynamicBackBtn font-semibold text-center"
                width="8rem"
                style="height: 44px; border-color: #f15a29"
                @click="displayProgress()"
              >
                See Comments
              </Button>
            </div>
          </div>
        </card>
        <div
          class="w-full mt-5 flex"
          v-if="
            approverDetails.userId === $AuthUser.id &&
            !$store.getters.hasSubscriptionExpired()
          "
        >
          <CTextarea
            placeholder="Insert Comment Here"
            variant="primary"
            class="mt-2"
            value=""
            :row="8"
            :height="90"
            :col="130"
            v-model="comment"
          />
        </div>
      </div>
      <div
        class="pl-3 mt-6 flex flex-row button-layout"
        v-if="
          approverDetails.userId === $AuthUser.id &&
          !$store.getters.hasSubscriptionExpired()
        "
      >
        <Button
          class="bg-dynamicBackBtn font-semibold border text-center mr-6 text-white"
          width="7rem"
          style="height: 44px"
          @click="approveRequest"
          :disabled="disableBtn"
        >
          Approve
        </Button>
        <Button
          class="text-dynamicBackBtn border border-solid border-dynamicBackBtn font-semibold text-center"
          width="7rem"
          style="height: 44px;"
          @click="disapproveRequest"
          :disabled="disableBtn"
        >
          Disapprove
        </Button>
      </div>
    </div>

    <RightSideBar v-if="seeComments" @close="seeComments = false" close-button>
      <template v-slot:title>
        <p class="text-darkPurple">Approvers ({{ approversNumber }})</p>
      </template>
      <template v-slot:subtitle>
        <p class="font-semibold text-darkPurple pb-4">
          See other approvers comments to this request.
        </p>
      </template>
      <div>
        <div v-for="(item, index) in requestProgress" :key="index">
          <ApproversCard
            class="flex bg-white pt-2 px-3 pb-4 mb-2 even:bg-ghostWhite border-l-4"
            :style="approveDetails(item.currentApprover)"
          >
            <div class="flex w-full">
              <div class="flex w-auto pr-1 py-2 inline-block">
                <img
                  v-if="item.photo != null && item.photo != ''"
                  class="round-md"
                  style="width: 40px; height: 40px"
                  :src="item.photo"
                />
                <div
                  style="height: 40px; width: 40px; border-radius: 5px"
                  class="text-blueCrayola border text-center font-semibold pt-2 mr-2"
                  v-else
                >
                  {{ $getInitials(`${item.fname} ${item.lname}`) }}
                </div>
              </div>
              <div class="flex flex-col w-3/4 mt-2">
                <div class="leading-tight">
                  <span class="block text-lg leading-tight">
                    <span class="block text-base"
                      >{{ item.fname }} {{ item.lname }}</span
                    >
                    <span class="text-romanSilver uppercase text-xs block">
                      <span v-if="item.userDesignation">
                        {{ item.userDesignation.name }}
                      </span>
                    </span>
                    <span class="block text-xs" v-if="item.actionTakenAt">
                      {{ $DATEFORMAT(new Date(item.actionTakenAt), "MMMM dd, yyyy") }}
                    </span>
                    <span v-else>-</span>
                  </span>
                </div>
                <div class="flex w-full mt-1 leading-tight">
                  <div class="w-full">
                    <span
                      class="pr-2 text-sm break-words leading-tight"
                      v-if="item.comment != ''"
                    >
                      <span class="font-normal text-romanSilver leading-tight">
                        Comment:
                      </span>
                      <span class="leading-tight">
                        {{ item.comment }}
                      </span>
                    </span>
                    <span class="leading-tight" v-else> --No Comment-- </span>
                  </div>
                </div>
              </div>
              <div class="flex-none w-4/12 text-base">
                <Tag
                  class="py-2 px-2 mx-2 float-right mt-8 text-sm"
                  :class="approveBgColor(item.currentApprover)"
                >
                  {{ approveText(item.currentApprover) }}
                </Tag>
              </div>
            </div>
          </ApproversCard>
        </div>
      </div>
    </RightSideBar>
  </div>
</template>

<script>
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Card from "@/components/Card";
import Button from "@/components/Button";
import Icon from "@/components/Icon";
import ProgressBar from "@/components/ProgressBar";
import ApproversCard from "@/components/ApproversCard";
import RightSideBar from "@/components/RightSideBar";
import Tag from "@/components/Tag";

export default {
  components: {
    BackButton,
    Breadcrumb,
    Card,
    Button,
    Icon,
    ProgressBar,
    CTextarea,
    Tag,
    ApproversCard,
    RightSideBar,
  },
  data() {
    return {
      disableBtn: false,
      requestId: "",
      initiatorId: "",
      comment: "",
      requestSlug: "",
      hasApproved: "",
      response: "",
      responseBg: "",
      success: false,
      loading: true,
      seeComments: false,
      breadcrumbs: [
        {
          disabled: false,
          text: "Approval",
          href: "approval",
          id: "Approval",
        },
        {
          disabled: false,
          text: "My Approvals",
          href: "my approvals",
          id: "My Approvals",
        },
        {
          disabled: false,
          text: "Disengage Details",
          id: "Disengage Details",
        },
      ],
      getDisengagedDetails: {},
      approverDetails: [],
      approversNumber: "",
      approvalsData: [],
      requestProgress: [],
    };
  },
  methods: {
    approveRequest() {
      this.disableBtn = true;
      const payload = {
        userId: this.$AuthUser.id,
        requestId: this.$route.params.id,
        comment: this.comment,
        requestSlug: this.approverDetails.requestSlug,
        hasApproved: true,
        whoToNotify: this.getDisengagedDetails.confirm?.confirmUserId,
        orgId: this.$orgId
      };
      this.$_respondApprovalRequest(payload)
        .then((result) => {
          this.$toasted.success(result.data.message, { duration: 5000 });
          this.$router.back({ name: "MyApprovals" });
          this.disableBtn = false;
        })
        .catch((err) => {
          this.$toasted.error("Request cannot be processed", {
            duration: 5000,
          });
          this.disableBtn = false;
          throw new Error(err);
        });
    },

    disapproveRequest() {
      this.disableBtn = true;
      const payload = {
        userId: this.$AuthUser.id,
        requestId: this.$route.params.id,
        comment: this.comment,
        requestSlug: this.approverDetails.requestSlug,
        hasApproved: false,
        whoToNotify: this.getDisengagedDetails.confirm?.confirmUserId,
        orgId: this.$orgId
      };
      this.$_respondApprovalRequest(payload)
        .then((result) => {
          this.$toasted.success("Request disapproved Successfully", {
            duration: 5000,
          });
          this.$router.back({ name: "MyApprovals" });
          this.disableBtn = false;
          return result;
        })
        .catch((err) => {
          this.$toasted.error("Request cannot be processed", {
            duration: 5000,
          });
          this.disableBtn = false;
          throw new Error(err);
        });
    },

    displayProgress() {
      this.seeComments = true;
    },

    getAllRequests() {
      const userId = this.$AuthUser.id;
      const params = "";
      this.$_getMyApprovalsfilter(params, userId, "?page=1&perPage=1000", '&platform=ess')
        .then((result) => {
          this.loading = false;
          const inProgressData = result.data.approvals;
          inProgressData.filter((a) => {
            if (a.requestId === this.$route.params.id) {
              this.approvalsData = a;
            }
            return {};
          });
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },

    getApprovalTurn() {
      this.$_getApprovalProgress(this.$route.params.id)
        .then((result) => {
          const approverDetails = result.data.data;
          this.requestProgress = result.data.data;
          approverDetails.filter(a => {
             if ((a.requestId === this.$route.params.id) && (a.isApprovalForAll === 'false')) {
              if (a.userId === this.$AuthUser.id) {
                this.approverDetails = a;
              }
              this.approversNumber = approverDetails.length;
            }
            else if ((a.requestId === this.$route.params.id) && (a.isApprovalForAll === 'true')) {
                if (a.currentApprover === "active") {
                  this.approverDetails = a;
                  this.approversNumber = approverDetails.length;
                }
              }
            return {};
          });
        })
        .catch((err) => {
          throw new Error(err);
        });
    },

    getDetails() {
      this.$_getEmployeeDisengageDetails(this.$route.params.id).then(
        (result) => {
          this.getDisengagedDetails = result.data;
          this.loading = false;
        }
      );
    },

    approveDetails(value) {
      if (value === "hasApproved") {
        return "border-color: #13B56A;";
      }
      if (value === "active") {
        return "border-color: #E99323;";
      }
      if (value === "inactive") {
        return "border-color: #333333;";
      }
      return "";
    },
    approveText(value) {
      if (value === "hasApproved") {
        return "Approved";
      }
      if (value === "active") {
        return "Pending";
      }
      if (value === "inactive") {
        return "Not Started";
      }
      return "";
    },
    approveBgColor(value) {
      if (value === "hasApproved") {
        return "text-mediumSeaGreen bg-light-sea-green";
      }
      if (value === "active") {
        return "text-carrotOrange bgCarrot";
      }
      if (value === "inactive") {
        return "text-black bg-gray-200";
      }
      return "";
    },
  },
  mounted() {
    this.getDetails();
    this.getApprovalTurn();
    this.getAllRequests();
  },
};
</script>

<style scoped>
.bgCarrot {
  background-color: rgba(233, 147, 35, 0.08);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
